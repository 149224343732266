import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from 'vue-router'
import Home from '../views/Home.vue'
import VueI18n from '@/language/index'
import { useUsersStore } from '@/pinia'
import { cancelAllRequest } from '@/service'
import { isHide } from '../utils/siteConfig'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: isHide()?.LODA
        ? isHide().LODA + '-网上购物平台 | 批发&代购,国际转运&商城'
        : 'LODA柬埔寨-网上购物平台 | 批发&代购,国际转运&本地快递,外卖订餐,商城',
    },
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册',
    },
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/searchList',
    name: 'searchList',
    meta: {
      title: '搜索商品',
    },
    component: () => import('@/views/SearchList.vue'),
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    meta: {
      title: '商品详情',
    },
    component: () => import('@/views/product-details.vue'),
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    meta: {
      requiresAuth: true,
      title: '购物车',
    },
    component: () => import('@/views/shopping-cart.vue'),
  },
  {
    path: '/placeOrder',
    name: 'placeOrder',
    meta: {
      requiresAuth: true,
      title: '提交订单',
    },
    component: () => import('@/views/place-order.vue'),
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    meta: {
      title: '用户中心',
    },
    component: () => import('@/views/user-center.vue'),
    redirect: '/userCenter/basic',
    children: [
      {
        path: '/userCenter/basic',
        name: 'basic',
        component: () => import('@/components/user-center/basic.vue'),
      },
      {
        path: '/userCenter/orders',
        name: 'orders',
        meta: {
          title: '我的订单',
        },
        component: () => import('@/components/user-center/order-list.vue'),
      },
      {
        path: '/userCenter/favorites',
        name: 'favorites',
        meta: {
          title: '收藏夹',
        },
        component: () => import('@/components/user-center/favorites.vue'),
      },
      {
        path: '/userCenter/myBox',
        name: 'myBox',
        meta: {
          title: '我的箱子',
        },
        component: () => import('@/components/user-center/user_box_list.vue'),
      },
      {
        path: '/userCenter/lodaDelivery',
        name: 'lodaDelivery',
        meta: {
          title: '乐达配送',
        },
        component: () => import('@/components/user-center/loda_delivery.vue'),
      },
      {
        path: '/userCenter/myPackage',
        name: 'myPackage',
        meta: {
          title: '我的包裹',
        },
        component: () =>
          import('@/components/user-center/transport_package.vue'),
      },
      {
        path: '/userCenter/settings',
        name: 'settings',
        meta: {
          title: '安全设置',
        },
        component: () =>
          import('@/components/user-center/account_settings.vue'),
      },
      {
        path: '/userCenter/stationLetter',
        name: 'stationLetter',
        meta: {
          title: '消息通知',
        },
        component: () => import('@/components/user-center/station_letter.vue'),
      },
      {
        path: '/userCenter/userInformation',
        name: 'userInformation',
        meta: {
          title: '个人资料',
        },
        component: () =>
          import('@/components/user-center/user-information.vue'),
      },
      {
        path: '/userCenter/wallet',
        name: 'wallet',
        meta: {
          title: '我的钱包',
        },
        component: () => import('@/components/user-center/wallet.vue'),
      },
      {
        path: '/userCenter/repository',
        name: 'repository',
        meta: {
          title: '提货仓库',
        },
        component: () => import('@/components/user-center/repository.vue'),
      },
      {
        path: '/userCenter/coupons',
        name: 'coupons',
        meta: {
          title: '我的优惠券',
        },
        component: () => import('@/components/user-center/coupon_list.vue'),
      },
      {
        path: '/userCenter/addressList',
        name: 'addressList',
        meta: {
          title: '收货地址',
        },
        component: () => import('@/components/user-center/address_list.vue'),
      },
      {
        path: '/userCenter/invitation',
        name: 'invitation',
        meta: {
          title: '邀请好友',
        },
        component: () => import('@/components/user-center/invitation.vue'),
      },
    ],
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta: {
      title: '订单详情',
    },
    component: () => import('@/views/order-details.vue'),
  },
  {
    path: '/mallHome',
    name: 'mallHome',
    component: () => import('@/views/mall-home.vue'),
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity.vue'),
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    meta: {
      title: '帮助中心',
    },
    component: () => import('@/views/help-center.vue'),
  },
  {
    path: '/callCharges',
    name: 'callCharges',
    meta: {
      title: 'LODA Live',
    },
    component: () => import('@/views/call-charges.vue'),
  },
  {
    path: '/express',
    name: 'express',
    meta: {
      title: 'LODA快递',
    },
    component: () => import('@/views/express.vue'),
    redirect: '/express/international',
    children: [
      {
        path: '/express/international',
        name: 'international',
        component: () => import('@/components/express/international.vue'),
      },
      {
        path: '/express/freightEstimate',
        name: 'freightEstimate',
        component: () => import('@/components/express/freight_estimate.vue'),
      },
      {
        path: '/express/siteQuery',
        name: 'siteQuery',
        component: () => import('@/components/express/site_query.vue'),
      },
      {
        path: '/express/packageInquiry',
        name: 'packageInquiry',
        component: () => import('@/components/express/package_inquiry.vue'),
      },
    ],
  },
  {
    path: '/recoverPassword',
    name: 'recoverPassword',
    meta: {
      title: '找回密码',
    },
    component: () => import('@/views/recover_password.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/notice.vue'),
  },
  {
    path: '/afterSalesOrder',
    name: 'afterSalesOrder',
    meta: {
      title: '售后处理',
    },
    component: () => import('@/views/after-sales-order.vue'),
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/article.vue'),
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    meta: {
      title: '关于我们',
    },
    component: () => import('@/views/AboutUs.vue'),
  },
  {
    path: '/IM',
    name: 'IM',
    meta: {
      title: '联系客服',
    },
    component: () => import('@/views/IM.vue'),
  },
  {
    path: '/Guide',
    name: 'Guide',
    meta: {
      title: '新手引导',
    },
    component: () => import('@/views/Guide.vue'),
  },
  {
    path: '/HotProducts',
    name: 'HotProducts',
    meta: {
      title: '热销榜',
    },
    component: () => import('@/views/hot_products.vue'),
  },
  {
    path: '/AppDownload',
    name: 'AppDownload',
    meta: {
      title: '下载LODA APP：柬埔寨最佳在线购物平台',
      content: {
        keywords:
          'LODA,download app,app,loda app,online shopping,android,ios,purchasing,wholesale,international shipping,delivery,express,food delivery,mall,product,coupons,shopping',
        description:
          'LODA APP is the largest e-commerce platform in Cambodia, providing online shopping, food delivery, logistics,,international shipping,delivery,express, local life services and more, allowing you to enjoy a convenient life anytime, anywhere.',
      },
    },
    component: () => import('@/views/app_download.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '维护中',
    },
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  },
]

const router = createRouter({
  scrollBehavior(to) {
    if (to.name != 'activity') {
      return { top: 0 }
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 35,
      }
    }
  },
  // history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const default_keywords =
  isHide().keywords ||
  'LODA,online shopping,purchasing,wholesale,international shipping,delivery,express,food delivery,marketplace,mall,product,coupons,B2B,B2C,online transactions,shopping,China,Cambodia'
const default_description =
  isHide().default_description ||
  'LODA Cambodia (loda.com.kh) is the largest online shopping e-commerce platform in Cambodia, providing e-commerce services such as online shopping, wholesale & purchasing, international shipping & delivery express, food delivery, local mall. We offer a wide range of products for you to choose from, and various promotions such as coupons and flash sales to help you save even more.'

router.beforeEach((to, from, next) => {
  let content: any = to.meta.content
  let head = document.getElementsByTagName('head')
  let meta: any = document.createElement('meta')
  let keywords: any = document.querySelector('meta[name="keywords"]')
  let description: any = document.querySelector('meta[name="description"]')
  keywords.setAttribute(
    'content',
    content ? content.keywords : default_keywords
  )
  description.setAttribute(
    'content',
    content ? content.description : default_description
  )

  meta.content = content
  head[0].appendChild(meta)

  if (typeof to.meta.title == 'string') {
    if (to.name != 'Home') {
      document.title = VueI18n.global.t(
        to.meta.title + ' - ' + isHide()?.LODA || 'LODA'
      )
    } else {
      document.title = VueI18n.global.t(to.meta.title)
    }
  } else {
    document.title =
      'LODA កម្ពុជា - Cambodia Online Shopping Platform | Wholesale & Purchasing, International Shipping & Delivery Express, Food Delivery, Mall'
  }

  if (from.name && to.fullPath != from.fullPath) {
    cancelAllRequest()
  }

  // next()
  // to.matched 是一个数组（匹配到是路由记录）
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    if (!useUsersStore().Token) {
      // 跳转到登录页面
      next({
        name: 'login',
        query: {
          // 通过 url 传递查询字符串参数
          redirect: to.fullPath, // 把登录成功需要返回的页面告诉登录页面
        },
      })
    } else {
      next() // 允许通过
    }
  } else {
    next() // 允许通过
  }
})

export default router
