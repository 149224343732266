import { createI18n } from 'vue-i18n'

import elementlangEn from 'element-plus/lib/locale/lang/en'
import elementlangZhCn from 'element-plus/lib/locale/lang/zh-cn'
import elementlangKm from 'element-plus/lib/locale/lang/km'
import localeLangZhCn from './lang/zh-cn'
import localeLangEn from './lang/en'
import localeLangKm from './lang/km'

const messages = {
  'zh-cn': {
    ...localeLangZhCn,
    ...elementlangZhCn,
  },
  en: {
    ...localeLangEn,
    ...elementlangEn,
  },
  km: {
    ...localeLangKm,
    ...elementlangKm,
  },
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'km', //默认显示的语言
  messages,
})

export default i18n
