import { objKeySort } from './sign'
import request from '@/service'

export const Get = (url, data: object) => {
  return request({
    url,
    method: 'get',
    data: objKeySort(data, url),
  })
}

export const Post = (url, data: object) => {
  return request({
    url,
    method: 'post',
    data: objKeySort(data, url),
  })
}

export const Put = (url, data: object) => {
  return request({
    url,
    method: 'put',
    data: objKeySort(data, url),
  })
}

export const Delete = (url, data: object) => {
  return request({
    url,
    method: 'delete',
    params: objKeySort(data, url),
  })
}

// 首字母大写
export const FUC = (str) => {
  str = str.toString()
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}
