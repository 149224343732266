import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './language'
import './assets/style/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import SuffixImg from '@/components/SuffixImg.vue'
import '@/assets/index'
import svgIcon from '@/components/SvgIcon.vue'
import vue3GoogleLogin from 'vue3-google-login'
import mitt from 'mitt'
import pinia from './pinia'
import { isHide } from './utils/siteConfig'

const app = createApp(App)
declare module 'vue' {
  export interface ComponentCustomProperties {
    $Bus: typeof Mit
  }
}

app.config.globalProperties.$isHide = isHide()

const Mit = mitt()
app.config.globalProperties.$Bus = Mit

app.config.globalProperties.$currency = {
  A: 'USD',
  B: '$',
}

app.config.globalProperties.$mapKey = 'AIzaSyDdT9vHuR0sLb0ZYOZEgo6EWwDaNQBsuxU'
app.use(vue3GoogleLogin, {
  clientId:
    '250157483079-4bajfjtnbuobiakpri5m6kai5mquep15.apps.googleusercontent.com',
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('suffix-img', SuffixImg).component('svg-icon', svgIcon)
app.use(router).use(ElementPlus).use(i18n).use(pinia).mount('#app')
