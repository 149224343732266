export const isHide = () => {
  return setting
}

//世界到家站点
const setting = {
  SearchType1: false, //搜索栏本地商城
  SearchType2: false, //搜索栏店铺
  seller: false,
  LODAMall: false,
  LODAPurchase: '海外购',
  LODALive: false,
  LODAAPP: false,
  LODAExpress: 'Express',
  Welcome: 'Welcome',
  Applink: false,
  ExpressNotices: false,
  RecommendModular: false,
  Activities: false,
  LODA: 'ztoworld',
  Mailbox: false,
  lodaDelivery: false,
  AboutUs: false,
  weixin: false,
  invitation: false,
  article: false,
  logoPng: 'ztoworld.png',
  ExternalLogin: false,
  login_banner: 'login_banner_cn_2.jpg',
  login_bgColor: '#eafefd',
  keywords:
    'ztoworld,online shopping,purchasing,wholesale,international shipping,delivery,express,food delivery,marketplace,mall,product,coupons,online transactions,shopping,China,Cambodia',
  default_description:
    'ztoworld is the online shopping e-commerce platform in Cambodia, providing e-commerce services such as online shopping, wholesale & purchasing, international shipping & delivery express, food delivery, local mall. We offer a wide range of products for you to choose from, and various promotions such as coupons and flash sales to help you save even more.',
}
